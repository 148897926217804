import axios from 'axios';
import { message as Message, Modal } from 'antd';
import {
  getLocalAccessToken, getLocalCsrfToken,
  renderListHtml, loginToAuth,
} from './util';
import { MESSAGES } from './constants';

const axiosConfig = {
  baseURL: process.env.REACT_APP_DOMAIN,
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' },
};

const request = axios.create(axiosConfig);
const changeMethods = ['post', 'delete', 'patch'];

// axios request interceptor
request.interceptors.request.use(
  async (config) => {
    const {
      headers, method, url,
    } = config;

    if (url.includes('/apis/')) {
      headers.Authorization = `Bearer ${getLocalAccessToken()}`;
    }

    if (changeMethods.includes(method) && url.includes('/apis/')) {
      const cdrfToken = getLocalCsrfToken();
      headers['X-CSRF-Token'] = cdrfToken || '';
    }

    return { ...config, headers };
  },
  (error) => {
    Promise.reject(error);
  },
);

// axios response interceptor
request.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.code === 'ECONNABORTED') {
      Message.error(MESSAGES.ERROR.REQUEST_TIMEOUT);
    } else {
      const { response: { status, statusText, data } } = error;
      const {
        fail_list: failList,
        success_list: successList,
      } = data?.data || {};

      switch (status) {
        case 400:
          if (successList && successList.length) {
            data.refreshList = true;
          }
          if (failList && failList.length) {
            const errorList = renderListHtml(failList);
            Modal.warning({
              title: data?.message || statusText,
              content: errorList,
              okText: MESSAGES.COMMON.CONFIRM,
            });
          } else {
            Message.error(data?.message || statusText);
          }
          break;
        case 401:
          loginToAuth();
          break;
        default:
          Message.error(statusText);
          break;
      }
    }
    return Promise.reject(error);
  },
);

export const getCsrfToken = async () => {
  const response = await request({
    url: '/session/token?_format=json',
    type: 'post',
  });

  return response.data;
};

export const authSSOLogin = async (data) => {
  const response = await request({
    url: '/oauth/token',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
  return response.data;
};

export const getMyInfo = async () => {
  const response = await request({
    url: '/apis/user/me',
    method: 'get',
  });

  return response.data.data?.result;
};

export const authLogout = async () => {
  const response = await request({
    url: '/apis/user-logout',
    method: 'get',
  });

  return response.data;
};

export const getUserList = async (params) => {
  const response = await request({
    url: '/apis/users',
    method: 'get',
    params,
  });

  return response.data;
};

export const addUser = async (data) => {
  const response = await request({
    url: '/apis/user/create',
    method: 'post',
    data,
  });

  return response.data;
};

export const deleteUser = async (data) => {
  const response = await request({
    url: `/apis/user/${data}`,
    method: 'delete',
  });

  return response.data;
};

/**
 * get permission list.
 * @function getPermissionList
 * @param {number} blob - blob id.
 * @param {number} folder - folder id.
 * @param {string} user
 * @param {string} role - e.g admin/user.
 * @param {number} pageNum - e.g start with 0.
 * @param {number} pageSize - e.g 0 = all.
 * @returns - permission list.
 */
export const getPermissionList = async ({
  blob = '',
  folder = '',
  user = '',
  role = '',
  pageNum = 0,
  pageSize = 10,
} = {}) => {
  const response = await request({
    url: '/apis/permission/list',
    method: 'get',
    params: {
      blob,
      folder,
      user,
      role,
      pageNum,
      pageSize,
    },
  });

  return response.data;
};

/**
 * create permissions.
 * @function createPermissions
 * @param {object} data
 *  data: {
 *    users: ['10', '23'],
 *    object_id: ['15', '93'],
 *    download_permission: 1,
 *    upload_permission: 0,
 *    delete_permission: 1
 *  }
 * @returns - status.
 */
export const createPermissions = async (data) => {
  const response = await request({
    url: '/apis/permission',
    method: 'post',
    data,
  });

  return response.data;
};

/**
 * update permissions.
 * @function updatePermissions
 * @param {object} data
 * @param {string} data.id - id list joined with comma. e.g - 11,12.
 * @param {object} data.permissions - file permission status.
 * e.g:
 * permissions: {
 *   download_permission: 1,
 *   upload_permission: 0,
 *   delete_permission: 1
 * }
 * @returns - status.
 */
export const updatePermissions = async (data) => {
  const response = await request({
    url: `/apis/permission/${data.id}`,
    method: 'patch',
    data: data.permissions,
  });

  return response.data;
};

/**
 * delete permissions.
 * @function deletePermissions
 * @param {string} id - id list joined with comma.
 * @returns - status.
 */
export const deletePermissions = async (id) => {
  const response = await request({
    url: `/apis/permission/${id}`,
    method: 'delete',
  });

  return response.data;
};

/**
 * get folder list.
 * @function getFolderList
 * @param {number} id - e.g 0 = blob, 1 = folder.
 * @param {number} file - e.g 1 = includes file, 0 = only folder.
 * @param {string} fileType
 *   - e.g '' = get all file, 'control' = only get file from fileManagement page
 * @returns - children list.
 */
export const getFolderList = async ({ id, file, fileType = '' }) => {
  const response = await request({
    url: `/apis/directory/${id}`,
    method: 'get',
    params: {
      file,
      fileType,
    },
  });

  return response.data.data?.result;
};

/**
 * create folder.
 * @function createFolder
 * @param {object} data - e.g {pid: 18, name: "folder3"}.
 * @returns - create status.
 */
export const createFolder = async (data) => {
  const response = await request({
    url: '/apis/directory/create',
    method: 'post',
    data,
  });

  return response.data;
};

/**
 * sync folder.
 * @function syncFolder
 * @param string folder .
 * @returns - sync status.
 */
export const syncFolder = async (id) => {
  const response = await request({
    url: `/apis/sync/${id}`,
    method: 'get',
  });

  return response.data;
};

/**
 * create folder.
 * @function deleteFolder
 * @param {number} id - e.g 0 = blob, 1 = folder.
 * @returns - status.
 */
export const deleteFolder = async ({ id }) => {
  const response = await request({
    url: `/apis/directory/${id}`,
    method: 'delete',
  });

  return response.data;
};

/**
 * upload file.
 * @function uploadFile
 * @param {object} data - e.g {folder_id: 18, files: [{file_name, file_data}]}.
 * @returns - status.
 */
export const uploadFile = async (data) => {
  const response = await request({
    url: '/apis/file/upload',
    method: 'post',
    data,
    timeout: 5 * 60 * 1000,
  });

  return response.data;
};

/**
 * upload split file.
 * @function uploadFile
 * @param {object} data
 * data - e.g {
 *  folder_id: 18, file_name: '33.txt', file_index: 1, file_data: 'FileRead.readAsDataURL(file)'
 * }.
 * @returns - status.
 */
export const uploadSplitFile = async (data, signal) => {
  const response = await request({
    url: '/apis/file_split/upload',
    method: 'post',
    data,
    signal,
    timeout: 8 * 60 * 1000,
  });

  return response.data;
};

/**
 * upload merge file.
 * @function uploadFile
 * @param {object} data - e.g {folder_id: 18, file_name: '33.txt', block_number: 5}.
 * @returns - status.
 */
export const uploadMergeFile = async (data) => {
  const response = await request({
    url: '/apis/file_block/merger',
    method: 'post',
    data,
    timeout: 5 * 60 * 1000,
  });

  return response.data;
};

/**
 * get file list.
 * @function getFileList
 * @param {string} id e.g list = all files, 12,24 = folder file list
 * @param {string} type
 *  - e.g:
 *    [empty] = all files
 *    file = uploaded files
 *    control = file control list
 *    operate = file has at least one operation permission [upload/delete/download]
 * @returns - file list.
 */
export const getFileList = async (params) => {
  const {
    id,
    type = 'file',
    pageNum = 0,
    pageSize = 10,
    name = '',
    sort = [],
  } = params;
  const response = await request({
    url: `/apis/file/${id}`,
    method: 'get',
    params: {
      type,
      pageNum,
      pageSize,
      name,
      sort,
    },
  });

  return response.data;
};

/**
 * delete file.
 * @function deleteFile
 * @param {string} ids
 * @returns - status.
 */
export const deleteFile = async (ids) => {
  const response = await request({
    url: `/apis/file/${ids}`,
    method: 'delete',
  });

  return response.data;
};

/**
 * download file.
 * @function deleteFile
 * @param {number} id
 * @returns - status.
 */
export const downloadFile = async (id) => {
  const response = await request({
    url: `/apis/file/${id}/download`,
    method: 'get',
  });

  return response.data;
};

/**
 * add file control.
 * @function addFileControl
 * @param {object} data e.g {folder_id: 18, file_name: 'file.txt'}.
 * @returns - status.
 */
export const addFileControl = async (data) => {
  const response = await request({
    url: '/apis/file-control/add',
    method: 'post',
    data,
  });

  return response.data;
};

/**
 * remove file control.
 * @function removeFileControl
 * @param {number} id
 * @returns - status.
 */
export const removeFileControl = async (id) => {
  const response = await request({
    url: `/apis/file-control/${id}/remove`,
    method: 'delete',
  });

  return response.data;
};

/**
 * get footer content.
 * @function getFooter
 * @returns - status.
 */
export const getFooter = async () => {
  const response = await request({
    url: '/apis/footer',
    method: 'get',
  });

  return response.data;
};

/**
 * get log list.
 * @function getLogList
 * @param {number} pageNum - e.g 0.
 * @param {number} pageSize - e.g 0 = all.
 * @param {number} createdStart - start time.
 * @param {number} createdEnd - end time.
 * @param {string} type - e.g 'blob', 'folder', 'file', 'file control', 'user', 'permission'.
 * @param {string} operation - e.g 'create', 'update', 'delete', 'download'.
 * @returns - log list.
 */
export const getLogList = async ({
  pageNum = 0,
  pageSize = 10,
  createdStart = '',
  createdEnd = '',
  type = '',
  operation = '',
} = {}) => {
  const response = await request({
    url: '/apis/logs',
    method: 'get',
    params: {
      pageNum,
      pageSize,
      createdStart,
      createdEnd,
      type,
      operation,
    },
  });

  return response.data;
};
