import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input,
  Button, Space, Row, Col,
} from 'antd';
import {
  MESSAGES,
} from '../common';

function UsersFilter({
  setQueryData,
  loading,
}) {
  const [form] = Form.useForm();

  const handleSetQueryData = (values) => {
    setQueryData((origin) => ({
      ...origin,
      ...values,
      pageNum: 0,
    }));
  };

  const onReset = () => {
    form.resetFields();
    handleSetQueryData(form.getFieldsValue());
  };

  return (
    <Form
      form={form}
      onFinish={handleSetQueryData}
    >
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
          <Form.Item
            name="name"
            label={MESSAGES.LABEL.USER_NAME}
          >
            <Input placeholder="" allowClear />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
          <Form.Item
            name="email"
            label={MESSAGES.LABEL.EMAIL}
          >
            <Input placeholder="" allowClear />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 3 }} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {MESSAGES.COMMON.SEARCH}
            </Button>
            <Button htmlType="button" onClick={onReset} disabled={loading}>
              {MESSAGES.COMMON.RESET}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

UsersFilter.propTypes = {
  setQueryData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UsersFilter;
