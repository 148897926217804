import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import {
  Button, Table, Space, Modal,
  Spin, Form, Input, TreeSelect,
  message as Message,
} from 'antd';
import { isArray } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FilesFilter } from '../components';
import {
  MESSAGES,
  AppContext,
  getFolderList,
  getFileList,
  addFileControl,
  removeFileControl,
  iconsMap,
  changeListDataFormat,
  updateTreeData,
} from '../common';

function FilesManagement() {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [treeData, setTreeData] = useState([]);
  const [fileControlList, setFileControlList] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [queryData, setQueryData] = useState({
    name: '',
    pageNum: 0,
    pageSize: 10,
    sort: [],
  });
  const [sortedInfo, setSortedInfo] = useState({ name: null, changed: null });

  const columns = [
    {
      title: MESSAGES.COMMON.BLOB,
      dataIndex: 'blob',
      key: 'blob',
    },
    {
      title: MESSAGES.COMMON.CONTAINER,
      // dataIndex: 'folder',
      // key: 'folder',
      dataIndex: 'path',
      key: 'path',
    },
    {
      title: MESSAGES.COMMON.FILE_SIZE,
      dataIndex: 'size',
      key: 'size',
      // sorter: { multiple: 2 },
      // sortDirections: ['ascend', 'descend'],
    },
    {
      title: MESSAGES.COMMON.FILE_NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: { multiple: 3 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.name,
    },
    {
      title: MESSAGES.COMMON.UPLOAD_TIME,
      dataIndex: 'changed',
      key: 'changed',
      sorter: { multiple: 1 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.changed,
    },
  ];

  const handleGetFileControlList = async (params) => {
    setTableLoading(true);
    try {
      const { data } = await getFileList({
        id: 'list',
        type: 'control',
        ...params,
      });
      setFileControlList(data.result);
      setTotal(data.total);
      setSelectedRowKeys([]);
    } catch (error) {
      // Handle error
    } finally {
      setTableLoading(false);
    }
  };

  const handleAddFileControl = async (params, callback) => {
    setModalLoading(true);
    try {
      await addFileControl(params);
      setQueryData((origin) => ({ ...origin, pageNum: 0 }));
      if (callback) {
        callback();
      }
    } finally {
      setModalLoading(false);
    }
  };

  const handleDeleteControl = async () => {
    setTableLoading(true);
    try {
      await removeFileControl(selectedRowKeys.join(','));
      setQueryData((origin) => ({ ...origin, pageNum: 0 }));
      Message.success(MESSAGES.SUCCESS.DELETE_SUCCESS);
    } catch (error) {
      // Handle error
      if (error?.response?.data?.refreshList) {
        setQueryData((origin) => ({ ...origin, pageNum: 0 }));
      }
    } finally {
      setTableLoading(false);
    }
  };

  const handleDeleteConfirm = () => {
    Modal.confirm({
      title: MESSAGES.COMMON.DELETE_CONFIRM,
      okText: MESSAGES.COMMON.CONFIRM,
      cancelText: MESSAGES.COMMON.CANCEL,
      onOk() {
        handleDeleteControl();
      },
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleSave = async () => {
    form.validateFields()
      .then((values) => {
        handleAddFileControl(values, handleCancel);
      }).catch((info) => {
        // Handle error
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setModalOpen(false);
  };

  const onLoadData = async (item) => {
    try {
      const { key } = item;
      let list = await getFolderList({ id: key, file: 0 });
      list = changeListDataFormat(list, { iconsMap });
      setTreeData((origin) => updateTreeData(origin, key, list));
    } catch (error) {
      // Handle error
    }
  };

  const renderIcon = useCallback((Icon) => <Icon />, []);

  useEffect(() => {
    setTreeData((state.blobs || []).map(
      (item) => ({
        ...item,
        selectable: false,
        isLeaf: !item.has_children,
        icon: ({ expanded, type }) => {
          const iconType = expanded ? iconsMap.get(`${type}Open`) : iconsMap.get(type);
          return renderIcon(iconType);
        },
      }),
    ));
  }, [state.blobs]);

  useEffect(() => {
    if (!state.isAuth) return;
    if (!state.user?.is_admin) {
      navigate('/403');
      return;
    }

    handleGetFileControlList(queryData);
  }, [state.isAuth, queryData]);

  const clearSort = () => {
    setSortedInfo({ name: null, changed: null });
    setQueryData((origin) => ({
      ...origin,
      sort: [],
    }));
  };

  const onChange = (pagination, filters, sorter) => {
    const sort = [];
    const sortInfo = {};
    if (isArray(sorter)) {
      sorter.forEach((v) => {
        sort.push({
          key: v.columnKey,
          order: v.order,
        });
        sortInfo[v.columnKey] = v.order;
      });
    } else {
      sort.push({
        key: sorter.columnKey,
        order: sorter.order,
      });
      sortInfo[sorter.columnKey] = sorter.order;
    }
    setSortedInfo(sortInfo);
    setQueryData((origin) => ({
      ...origin,
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
      sort,
    }));
  };

  return (
    <>
      <FilesFilter
        loading={tableLoading}
        setQueryData={setQueryData}
        reset={clearSort}
      />
      <Space>
        <Button type="primary" onClick={() => setModalOpen(true)}>
          {MESSAGES.COMMON.CREATE}
        </Button>
        <Button
          type="primary"
          onClick={handleDeleteConfirm}
          disabled={!selectedRowKeys.length}
        >
          {MESSAGES.COMMON.DELETE}
        </Button>
      </Space>
      <div className="table">
        <Table
          bordered
          rowSelection={{
            ...rowSelection,
          }}
          loading={tableLoading}
          rowKey="id"
          columns={columns}
          dataSource={fileControlList}
          onChange={onChange}
          pagination={{
            showSizeChanger: false,
            current: queryData.pageNum + 1,
            pageSize: queryData.pageSize,
            total,
            showTotal: (totalNum, range) => MESSAGES.COMMON.TOTAL(totalNum, range),
          }}
        />
      </div>
      <Modal
        open={modalOpen}
        title={MESSAGES.COMMON.CREATE + MESSAGES.COMMON.FILE_CONTROL}
        cancelText={MESSAGES.COMMON.CANCEL}
        okText={MESSAGES.COMMON.SAVE}
        onCancel={handleCancel}
        onOk={handleSave}
      >
        <Spin spinning={modalLoading}>
          <Form
            form={form}
            preserve={false}
            wrapperCol={{
              span: 16,
            }}
          >
            <Form.Item
              label={`${MESSAGES.COMMON.FOLDER}`}
              name="folder_id"
              rules={[
                {
                  required: true,
                  message: MESSAGES.ERROR.SELECT_REQUIRED,
                },
              ]}
            >
              <TreeSelect
                treeIcon
                height={350}
                treeData={treeData}
                loadData={onLoadData}
              />
            </Form.Item>
            <Form.Item
              label={MESSAGES.COMMON.FILE_NAME}
              name="file_name"
              rules={[
                {
                  required: true,
                  message: MESSAGES.ERROR.REQUIRED,
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Form>
        </Spin>

      </Modal>
    </>
  );
}

export default FilesManagement;
