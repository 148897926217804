import React, { useEffect, useState, useContext } from 'react';
import {
  Button, Table, Space, Modal,
  message as Message,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { AddUser, UsersFilter } from '../components';
import {
  MESSAGES,
  AppContext,
  getUserList,
  addUser,
  deleteUser,
} from '../common';

const columns = [
  {
    title: MESSAGES.LABEL.USER_NAME,
    dataIndex: 'name',
  },
  {
    title: MESSAGES.LABEL.EMAIL,
    dataIndex: 'email',
  },
  {
    title: MESSAGES.LABEL.WWID,
    dataIndex: 'wwid',
  },
  {
    title: MESSAGES.LABEL.ROLE,
    dataIndex: 'roles',
  },
  {
    title: MESSAGES.LABEL.CREATED,
    dataIndex: 'created',
  },
  {
    title: MESSAGES.LABEL.CREATED_BY,
    dataIndex: 'created_by',
  },
];

function Users() {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [total, setTotal] = useState(0);
  const [queryData, setQueryData] = useState({
    name: '',
    email: '',
    pageNum: 0,
    pageSize: 10,
  });

  const getAllUserList = async () => {
    const { data } = await getUserList();
    dispatch({
      type: 'userList',
      payload: { userList: data.result },
    });
  };

  const handleGetUserList = async (params = {}) => {
    setTableLoading(true);
    try {
      const { data } = await getUserList({
        ...queryData,
        ...params,
      });
      setUserList(data.result);
      setTotal(data.total);
      setSelectedRowKeys([]);
      getAllUserList();
    } catch (error) {
      // Handle error
    } finally {
      setTableLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    setTableLoading(true);
    try {
      await deleteUser(selectedRowKeys.join(','));
      Message.success(MESSAGES.SUCCESS.DELETE_SUCCESS);
      setQueryData((origin) => ({ ...origin, pageNum: 0 }));
    } catch (error) {
      // Handle error
      if (error?.response?.data?.refreshList) {
        setQueryData((origin) => ({ ...origin, pageNum: 0 }));
      }
    } finally {
      setTableLoading(false);
    }
  };

  const handleDeleteConfirm = () => {
    Modal.confirm({
      title: MESSAGES.COMMON.DELETE_CONFIRM,
      okText: MESSAGES.COMMON.CONFIRM,
      cancelText: MESSAGES.COMMON.CANCEL,
      onOk() {
        handleDeleteUser();
      },
    });
  };

  const handleAddUser = async (data, callback) => {
    setModalLoading(true);
    try {
      await addUser(data);
      Message.success(MESSAGES.SUCCESS.CREATE_SUCCESS);
      setQueryData((origin) => ({ ...origin, pageNum: 0 }));

      if (callback) {
        callback();
      }
    } catch (error) {
      // Handle error
    } finally {
      setModalLoading(false);
    }
  };

  useEffect(() => {
    if (!state.isAuth) return;
    if (!state.user?.is_admin) {
      navigate('/403');
      return;
    }
    // API call to init user list.
    handleGetUserList(queryData);
  }, [state.isAuth, queryData]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleModalStatus = (status) => {
    setIsModalOpen(status);
  };

  const onChange = (pagination) => {
    setQueryData((origin) => ({
      ...origin,
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
    }));
  };

  return (
    <>
      <UsersFilter
        loading={tableLoading}
        setQueryData={setQueryData}
      />
      <Space>
        <Button type="primary" onClick={() => handleModalStatus(true)}>
          {MESSAGES.COMMON.CREATE}
        </Button>
        <Button
          type="primary"
          onClick={handleDeleteConfirm}
          disabled={!selectedRowKeys.length}
        >
          {MESSAGES.COMMON.DELETE}
        </Button>
      </Space>
      <Table
        bordered
        rowSelection={{
          ...rowSelection,
        }}
        loading={tableLoading}
        rowKey="id"
        columns={columns}
        dataSource={userList}
        onChange={onChange}
        pagination={{
          showSizeChanger: false,
          current: queryData.pageNum + 1,
          pageSize: queryData.pageSize,
          total,
          showTotal: (totalNum, range) => MESSAGES.COMMON.TOTAL(totalNum, range),
        }}
      />

      <AddUser
        isModalOpen={isModalOpen}
        modalLoading={modalLoading}
        handleModalStatus={handleModalStatus}
        handleAddUser={handleAddUser}
      />
    </>
  );
}

export default Users;
