import React, {
  useContext, useEffect, useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Form, Spin, Tree,
  Select, Checkbox,
} from 'antd';
import {
  MESSAGES,
  AppContext,
  formatPermissionVal,
  changeListDataFormat,
  updateTreeData,
  getFolderList,
  iconsMap,
} from '../common';

const permissionOptions = [
  {
    label: MESSAGES.COMMON.UPLOAD,
    value: 'upload_permission',
  },
  {
    label: MESSAGES.COMMON.DOWNLOAD,
    value: 'download_permission',
  },
  {
    label: MESSAGES.COMMON.DELETE,
    value: 'delete_permission',
  },
];

function AddPermission({
  formType,
  isModalOpen,
  handleModalStatus,
  handleUpdatePermission,
  modalLoading,
  blobs,
  selectedUsers,
}) {
  const { state } = useContext(AppContext);
  const [form] = Form.useForm();
  const [treeData, setTreeData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const handleCancel = () => {
    form.resetFields();
    handleModalStatus(false);
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        // Call API to Save data & refresh list data.
        const formData = values;
        const permissions = formatPermissionVal(permissionOptions, values.permissions);
        handleUpdatePermission({
          ...formData,
          ...permissions,
        }, handleCancel);
      })
      .catch((info) => {
        // Catch validate error infos.
      });
  };

  const onLoadData = async (item) => {
    try {
      const { key } = item;
      let list = await getFolderList({ id: key, file: 1, fileType: 'control' });
      list = changeListDataFormat(list, { iconsMap });
      setTreeData((origin) => updateTreeData(origin, key, list));
    } catch (error) {
      // Handle error
    }
  };

  const renderIcon = useCallback((Icon) => <Icon />, []);

  useEffect(() => {
    setTreeData((blobs || []).map(
      (item) => ({
        ...item,
        isLeaf: !item.has_children,
        icon: ({ expanded, type }) => {
          const iconType = expanded ? iconsMap.get(`${type}Open`) : iconsMap.get(type);
          return renderIcon(iconType);
        },
      }),
    ));
  }, [blobs, renderIcon]);

  useEffect(() => {
    if (!isModalOpen) {
      setCheckedKeys([]);
    }
  }, [isModalOpen]);

  const onTreeChecked = ({ checked }) => {
    if (formType == 'update') {
      const checkedArr = [checked.pop()];
      setCheckedKeys(checkedArr);
      form.setFieldValue('object_id', checkedArr);
    } else {
      setCheckedKeys(checked);
      form.setFieldValue('object_id', checked);
    }
    form.validateFields(['object_id']);
  };

  return (
    <Modal
      open={isModalOpen}
      title={formType === 'add' ? MESSAGES.COMMON.CREATE : MESSAGES.COMMON.UPDATE}
      cancelText={MESSAGES.COMMON.CANCEL}
      okText={MESSAGES.COMMON.SAVE}
      onCancel={handleCancel}
      onOk={handleSave}
    >
      <Spin spinning={modalLoading}>
        <Form
          form={form}
          preserve={false}
          fields={[{
            name: 'users',
            value: selectedUsers,
          }]}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {
            (formType === 'add' || formType === 'update') && (
              <>
                <Form.Item
                  label={MESSAGES.LABEL.USER}
                  name="users"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.ERROR.SELECT_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    disabled={formType === 'update'}
                  >
                    {
                      state.userList.map(
                        (item) => (
                          <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                        ),
                      )
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label={`${MESSAGES.COMMON.FOLDER}/${MESSAGES.COMMON.FILE}`}
                  name="object_id"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.ERROR.SELECT_REQUIRED,
                    },
                  ]}
                >
                  {
                    treeData && !!treeData.length ? (
                      <Tree
                        checkable
                        checkStrictly
                        showIcon
                        height={350}
                        treeData={treeData}
                        onCheck={onTreeChecked}
                        checkedKeys={checkedKeys}
                        loadData={onLoadData}
                      />
                    ) : (
                      <div className="empty-data">{MESSAGES.COMMON.EMPTY_DATA}</div>
                    )
                  }
                </Form.Item>
              </>
            )
          }

          <Form.Item
            label={MESSAGES.LABEL.PERMISSION}
            name="permissions"
            rules={[
              {
                required: true,
                message: MESSAGES.ERROR.SELECT_REQUIRED,
              },
            ]}
          >
            <Checkbox.Group options={permissionOptions} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

AddPermission.propTypes = {
  formType: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  handleModalStatus: PropTypes.func.isRequired,
  handleUpdatePermission: PropTypes.func.isRequired,
  modalLoading: PropTypes.bool.isRequired,
  blobs: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AddPermission;
