import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Space,
  Table,
  Modal,
  Upload,
  message as Message,
} from 'antd';
import {
  DownloadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  ExclamationOutlined,
} from '@ant-design/icons';
import { isArray } from 'lodash';
import {
  getDownloadLink,
  humanFileSize,
  MESSAGES,
  UPLOAD_CHUNK_SIZE,
  UPLOAD_MAX_SIZE,
} from '../common';
import MultipleUpload from './MultipleUpload';
import FilesFilter from './FilesFilter';
import styles from '../scss/modules/files.module.scss';

function FileContent({
  files,
  selectedRowKeys,
  actionPermission,
  onChange,
  onDelete,
  onSmallFileUpload,
  onLargeFileUpload,
  queryData,
  filesTotal,
  onTableChange,
  activeFolderNode,
  loading,
  spinningTip,
  setQueryData,
}) {
  const [batchDownloadLink, setBatchDownloadLink] = useState('');
  const [sortedInfo, setSortedInfo] = useState({ name: null, changed: null });
  const [openModal, setOpenModal] = useState(false);
  const maxFileSize = humanFileSize(UPLOAD_MAX_SIZE);

  const handleDelete = (id) => {
    Modal.confirm({
      title: MESSAGES.COMMON.DELETE + MESSAGES.COMMON.FILE,
      icon: <ExclamationCircleOutlined />,
      okText: MESSAGES.COMMON.CONFIRM,
      cancelText: MESSAGES.COMMON.CANCEL,
      onOk: () => {
        onDelete(id);
      },
    });
  };

  const clearSort = () => {
    setSortedInfo({ name: null, changed: null });

    setQueryData((origin) => ({
      ...origin,
      sort: [],
    }));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sort = { name: null, changed: null };
    if (sorter == null) {
      // do nothing
    } else if (isArray(sorter)) {
      sorter.forEach((v) => {
        sort[v.columnKey] = v.order;
      });
    } else {
      sort[sorter.columnKey] = sorter.order;
    }
    setSortedInfo(sort);
    onTableChange(pagination, filters, sorter);
  };

  const checkUploadFileName = (file, reqName) => {
    const { name: uploadName, size } = file;

    if (uploadName !== reqName) {
      Message.error(MESSAGES.ERROR.UPLOAD_SAME_NAME);
      return false;
    }
    if (size > UPLOAD_MAX_SIZE) {
      Message.error(MESSAGES.COMMON.BACK_UPLOAD_MAX_SIZE(maxFileSize));
    } else if (size > UPLOAD_CHUNK_SIZE) {
      onLargeFileUpload(file);
    } else {
      onSmallFileUpload([file]);
    }

    return false;
  };

  const onDownload = () => {
    const content = !batchDownloadLink
      ? MESSAGES.ERROR.NO_DOWNLOAD_PERMISSION : MESSAGES.ERROR.PARTIAL_DOWNLOAD_PERMISSION;
    Modal.warning({
      content,
      onOk: () => {
        if (batchDownloadLink) {
          window.location.href = batchDownloadLink;
        }
      },
    });
  };

  const columns = [
    {
      title: MESSAGES.COMMON.FILE_NAME,
      dataIndex: 'name',
      key: 'name',
      render: (_, { name, new_file: hasNewFile }) => {
        if (hasNewFile) {
          return (
            <span>
              {name}
              <ExclamationOutlined className="new-file-tag" />
            </span>
          );
        }

        return <span>{name}</span>;
      },

      sorter: { multiple: 1 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.name,
    },
    {
      title: MESSAGES.COMMON.FILE_SIZE,
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: MESSAGES.COMMON.UPLOAD_TIME,
      dataIndex: 'changed',
      key: 'changed',
      render: (change, {
        file_control: fileControl,
        file_existence: fileExistence,
      }) => {
        if (fileControl && !fileExistence) {
          return '—';
        }

        return change;
      },
      sorter: { multiple: 3 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.changed,
    },
    {
      title: MESSAGES.COMMON.ACTION,
      className: 'actions',
      key: 'action',
      render: (_, {
        id, name,
        download_permission: downloadPerms,
        delete_permission: deletePerms,
        file_existence: fileExistence,
        upload_permission: uploadPerms,
      }) => (
        <Space size="middle">
          <Upload
            showUploadList={false}
            beforeUpload={(file) => checkUploadFileName(file, name)}
          >
            <Button
              type="text"
              icon={<UploadOutlined />}
              disabled={!+uploadPerms}
              title={MESSAGES.COMMON.UPLOAD + MESSAGES.COMMON.FILE}
            />
          </Upload>
          <Button
            type="text"
            href={getDownloadLink([id], files)}
            icon={<DownloadOutlined />}
            disabled={!fileExistence || !+downloadPerms}
            title={MESSAGES.COMMON.DOWNLOAD + MESSAGES.COMMON.FILE}
          />
          <Button
            danger
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(id)}
            disabled={!fileExistence || !+deletePerms}
            title={MESSAGES.COMMON.DELETE + MESSAGES.COMMON.FILE}
          />
        </Space>
      ),
    },
  ];

  const { onlyBlobActive } = actionPermission;
  const fileUploadPerms = onlyBlobActive || !activeFolderNode;
  const fileDeletePerms = onlyBlobActive || !activeFolderNode || !selectedRowKeys.length;
  const fileDowloadPerms = onlyBlobActive || !activeFolderNode || !selectedRowKeys.length;

  useEffect(() => {
    if (selectedRowKeys && selectedRowKeys.length) {
      setBatchDownloadLink(getDownloadLink(selectedRowKeys, files));
    } else {
      setBatchDownloadLink('');
    }
  }, [selectedRowKeys, files]);

  const setRowClassName = ({
    file_control: fileControl,
    file_existence: fileExistence,
  }) => ((fileControl && !fileExistence) ? 'only-control-file' : '');

  const rowSelection = {
    onChange,
    selectedRowKeys,
    getCheckboxProps: ({
      file_existence: fileExistence,
      file_control: fileControl,
    }) => ({
      disabled: fileControl && !fileExistence,
    }),
  };

  return (
    <>
      {/* File Action */}
      <Space
        style={{
          margin: 15,
        }}
      >
        <Button
          icon={<UploadOutlined />}
          disabled={fileUploadPerms}
          title={MESSAGES.COMMON.BATCH_UPLOAD_FILE}
          onClick={() => setOpenModal(true)}
        >
          {MESSAGES.COMMON.BATCH_UPLOAD_FILE}
        </Button>
        <Button
          icon={<DeleteOutlined />}
          disabled={fileDeletePerms}
          onClick={() => handleDelete()}
          title={MESSAGES.COMMON.DELETE + MESSAGES.COMMON.FILE}
        >
          {MESSAGES.COMMON.DELETE + MESSAGES.COMMON.FILE}
        </Button>
        <Button
          icon={<DownloadOutlined />}
          onClick={onDownload}
          disabled={fileDowloadPerms}
          title={MESSAGES.COMMON.DOWNLOAD + MESSAGES.COMMON.FILE}
        >
          {MESSAGES.COMMON.DOWNLOAD + MESSAGES.COMMON.FILE}
        </Button>
      </Space>

      {/* File Content */}
      <div className={styles.files}>
        {
          (!!activeFolderNode && !!activeFolderNode.new_file) && (
            <div className="new-file-tips">
              <ExclamationOutlined className="new-file-tag" />
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {`:  ${MESSAGES.COMMON.NEW_FILE_TIPS}`}
              </span>
            </div>
          )
        }

        <FilesFilter
          loading={loading}
          setQueryData={setQueryData}
          queryData={queryData}
          reset={clearSort}
          enabled={!!activeFolderNode}
        />
        <Table
          bordered
          className={styles.table}
          dataSource={files}
          columns={columns}
          rowClassName={setRowClassName}
          rowKey={(record) => record.id}
          rowSelection={rowSelection}
          onChange={handleTableChange}
          pagination={{
            showSizeChanger: false,
            current: queryData.pageNum + 1,
            pageSize: queryData.pageSize,
            total: filesTotal,
            showTotal: (totalNum, range) => MESSAGES.COMMON.TOTAL(totalNum, range),
          }}
        />
      </div>

      <MultipleUpload
        open={openModal}
        setOpenModal={setOpenModal}
        onSmallFileUpload={onSmallFileUpload}
        onLargeFileUpload={onLargeFileUpload}
        loading={loading}
        spinningTip={spinningTip}
      />
    </>
  );
}

FileContent.defaultProps = {
  activeFolderNode: null,
};

const filesShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
});

FileContent.propTypes = {
  actionPermission: PropTypes.shape({
    onlyBlobActive: PropTypes.bool.isRequired,
  }).isRequired,
  files: PropTypes.arrayOf(filesShape).isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSmallFileUpload: PropTypes.func.isRequired,
  onLargeFileUpload: PropTypes.func.isRequired,
  onTableChange: PropTypes.func.isRequired,
  filesTotal: PropTypes.number.isRequired,
  queryData: PropTypes.shape({
    pageNum: PropTypes.number,
    pageSize: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  setQueryData: PropTypes.func.isRequired,
  activeFolderNode: PropTypes.shape(PropTypes.objectOf),
  loading: PropTypes.bool.isRequired,
  spinningTip: PropTypes.node.isRequired,
};

export default FileContent;
