import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input,
  Button, Space, Row, Col,
} from 'antd';
import {
  MESSAGES,
} from '../common';

function FilesFilter({
  setQueryData,
  queryData,
  loading,
  enabled,
  reset,
}) {
  const [form] = Form.useForm();
  const handleSetQueryData = (values) => {
    setQueryData((origin) => ({
      ...origin,
      ...values,
      pageNum: 0,
    }));
  };

  const onReset = () => {
    form.resetFields();
    reset();
    handleSetQueryData(form.getFieldsValue());
  };

  useEffect(() => {
    form.setFieldValue('name', queryData.name);
  }, [queryData.name]);

  return (
    <Form
      form={form}
      onFinish={handleSetQueryData}
    >
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
          <Form.Item
            name="name"
            label={MESSAGES.COMMON.FILE_NAME}
          >
            <Input placeholder="" allowClear disabled={loading || !enabled} />
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 3 }} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={loading || !enabled}>
              {MESSAGES.COMMON.SEARCH}
            </Button>
            <Button htmlType="button" onClick={onReset} disabled={loading || !enabled}>
              {MESSAGES.COMMON.RESET}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

FilesFilter.propTypes = {
  queryData: PropTypes.shape({
    pageNum: PropTypes.number,
    pageSize: PropTypes.number,
    name: PropTypes.string,
  }),
  setQueryData: PropTypes.func.isRequired,
  reset: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  enabled: PropTypes.bool,
};

FilesFilter.defaultProps = {
  enabled: true,
  queryData: {
    pageNum: 0,
    pageSize: 20,
    name: '',
  },
  reset: () => { },
};

export default FilesFilter;
