import React, { useEffect, useState } from 'react';
import { Result } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { MESSAGES } from './common';
import Logo from './images/logo.png';
import styles from './scss/modules/header.module.scss';

const error = {
  403: {
    title: '403',
    subTitle: MESSAGES.ERROR_PAGE[403],
  },
  500: {
    title: '500',
    subTitle: MESSAGES.ERROR_PAGE[500],
  },
  404: {
    title: '404',
    subTitle: MESSAGES.ERROR_PAGE[404],
  },
};

function ErrorPage() {
  const { result } = useParams();
  const [status, setStatus] = useState(403);

  useEffect(() => {
    let errorStatus = result;
    if (!errorStatus || !error[errorStatus]) {
      errorStatus = 403;
    }
    setStatus(errorStatus);
  }, [result]);

  return (
    <div>
      <div className={styles.logo}>
        <img src={Logo} alt="iDiscover China Portal" />
      </div>
      <Result
        status={status}
        title={error[status].title}
        subTitle={error[status].subTitle}
        extra={
          <Link type="primary" to="/">{MESSAGES.COMMON.BACK_TO_HOME}</Link>
        }
      />
    </div>
  );
}

export default ErrorPage;
